module.exports = [{
      plugin: require('/Users/ddosen/dev/gatsby_v2d/node_modules/gatsby-plugin-emotion/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/ddosen/dev/gatsby_v2d/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/ddosen/dev/gatsby_v2d/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
